@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans-Medium.woff') format('woff'), /* Pretty Modern Browsers */
         url('IBMPlexSans-Medium.ttf')  format('truetype'); /* Safari, Android, iOS */
    font-weight: normal;
    font-style: normal;
    /* font-display: swap; */
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans-Bold.woff') format('woff'), /* Pretty Modern Browsers */
         url('IBMPlexSans-Bold.ttf')  format('truetype'); /* Safari, Android, iOS */
    font-weight: bold;
    font-style: normal;
    /* font-display: swap; */
}
