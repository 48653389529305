body {
  font-family: 'IBM Plex Sans', Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #333;
  margin: 0;
  padding: 0;
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 720px;
  padding: 1em;
}

.heading-container {
  text-align: center;
  margin-bottom: 20px;
  height: 80px;
}

.footer {
  margin-top: 50px;
  border-top: 1px solid #eee;
  padding: 20px 0;
  font-size: 12px;
  color: #999;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #222;
  font-weight: bold;
  margin: 0.5em 0;
}

h2 {
	font-weight: normal;
}

label {
  color: #232323;
  font-size: 0.85em;
  font-weight: normal;
  padding: 0.5em;
  text-transform: uppercase;
}

.hint {
  margin: 15px 0;
  font-style: italic;
  color: #999;
}

.columnsContainer,
footer,
header {
  position: relative;
  margin: 0.5em;
}
.leftColumn,
.rightColumn,
footer,
header {
  border: 1px solid #ccc;
  padding: 1.25em;
}
.leftColumn {
  margin-bottom: 0.5em;
}

/* MEDIA QUERIES */
@media screen and (min-width: 47.5em) {
  .timerContainer {
    margin-right: 21em;
  }
  .controlsContainer {
    position: absolute;
    top: 0;
    right: 0;
	width: 20em;
	margin-top: 20px;
  }
}
